import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap } from 'rxjs';
import { ProductService } from '../services/product.service';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokProductsResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(StoryblokService);
  const productService = inject(ProductService);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('PricingBoxList') || components.has('ProductComparisonList')) {
        return productService.getAllListedProducts();
      }
      return of(undefined);
    })
  );
};
