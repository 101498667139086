import { ErrorHandler, Injectable, Injector, inject } from '@angular/core';

@Injectable()
export class CustomErrorHandlerService implements ErrorHandler {
  private errorWrapper = inject<{ error: Error }>('ERROR_WRAPPER' as never, { optional: true });
  private injector = inject(Injector);

  handleError(error: Error) {
    console.error(error);

    error = error['originalError'] || error['error'] || error;

    if (error['rejection']?.message?.includes('Http failure response')) {
      error = new Error(`${error['rejection'].message} - ${error['rejection'].error?.message}`);
    }

    if (this.errorWrapper) {
      this.errorWrapper.error = error;
    } else {
      import('./services/sentry.service').then(({ SentryService }) => {
        const service = this.injector.get(SentryService);
        service.captureException(error);
      });
    }
  }
}
