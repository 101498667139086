import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Injectable, NgZone, PLATFORM_ID, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ISbStory } from '@storyblok/js';
import { BehaviorSubject } from 'rxjs';
import { ScreenSizes } from '../classes/screen-sizes.class';
import { thirdPartyScriptDelay } from '../consts';
import { getAllRouteData } from '../lib/get-all-route-data';
import { getFullPath } from '../lib/get-full-path';
import { isSafari } from '../lib/is-safari';
import { loadScript } from '../lib/load-script';
import { parseJwt } from '../lib/parse-jwt';
import { sleep } from '../lib/sleep';
import { AppConfigService } from '../providers/config.service';
import { AnalyticsService } from './analytics.service';
import { BrowserService } from './browser.service';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}
@Injectable({
  providedIn: 'root',
})
export class GoogleOneTapService {
  private router = inject(Router);
  private route = inject(ActivatedRoute);
  private zone = inject(NgZone);
  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);
  private browserService = inject(BrowserService);
  private config = inject(AppConfigService);
  private analytics = inject(AnalyticsService);
  private loadedGoogleOneTap = false;

  public showOneTap$ = new BehaviorSubject<boolean>(false);

  private onShowOneTap$ = this.showOneTap$.subscribe(showOneTap => {
    if (showOneTap) this.loadGoogleOneTap();
  });

  private routeSub$ = this.router.events.subscribe(event => {
    if (event instanceof NavigationEnd) {
      return this.showOneTap$.next(this.canShowGoogleLogin(getAllRouteData(this.route.snapshot).story));
    }
  });

  private canShowGoogleLogin(story: ISbStory['data']['story']): boolean {
    if (isPlatformServer(this.platformId)) return false;
    if (isSafari()) return false;
    if (this.config.env === 'local') return false;
    if (this.browserService.getCookies()?.['picflow-loggedin'] === 'true') return false;
    if (this.route.snapshot.queryParams.noOneTap) return false;
    if (this.config.isTenantDomain) return false;
    const path = getFullPath(this.route.snapshot);
    if (['gallery', 'maintenance', 'learn', 'blog', 'press'].includes(path[0])) return false;
    if (path[0] === 'auth') return !this.route.snapshot.queryParams.email;
    if (ScreenSizes.screenSize() === 'mobile') return false;
    if (story?.content?.hideGoogleLogin) return false;
    return true;
  }

  private async onGoogleTapLogin(e: { credential: string }) {
    if (!e?.credential) return;
    const content = parseJwt(e.credential);
    if (!content) return;

    this.analytics.track('one-tap-login', false, { path: '/' + getFullPath(this.route.snapshot).join('/') });
    await sleep(250); // Waiting a bit before redirecting to make sure the analytics event is sent

    this.router.navigate(['/auth', 'login', 'google'], { queryParams: { email: content.email } });
  }

  private loadGoogleOneTap() {
    if (this.loadedGoogleOneTap) return;
    this.loadedGoogleOneTap = true;
    if (ScreenSizes.screenSize() === 'mobile') {
      // Not removing the class is on purpose.
      this.document.body.classList.add('show-mobile-google-login');
    }

    this.zone.runOutsideAngular(async () => {
      await sleep(thirdPartyScriptDelay); // Waiting a bit before loading the google one tap client

      await loadScript('https://accounts.google.com/gsi/client', { ignoreErrors: true });
      window.google.accounts.id.initialize({
        client_id: '387343500342-obn0crc436d0kubai59g2frmat1sbmvl.apps.googleusercontent.com',
        callback: this.onGoogleTapLogin.bind(this),
        prompt_parent_id: 'g_id_onload',
        context: 'signin',
        cancel_on_tap_outside: false,
        itp_support: true,
        use_fedcm_for_prompt: true,
      });
      window.google.accounts.id.prompt();
    });
  }
}
