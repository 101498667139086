import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { PLATFORM_ID, inject } from '@angular/core';
import { catchError, map, of, tap } from 'rxjs';
import { GROWTHBOOK } from '../growthbook';
import { randomString } from '../lib/random-string';
import { AppConfigService } from '../providers/config.service';
import { BrowserService } from '../services/browser.service';
import { StorageService } from '../services/storage.service';

export const growthBookGuard = () => {
  const growthBook = inject(GROWTHBOOK);
  const browserService = inject(BrowserService);
  const http = inject(HttpClient);
  const config = inject(AppConfigService);
  const storage = inject(StorageService);
  const platformId = inject(PLATFORM_ID);

  let visitorId: string;
  if (isPlatformBrowser(platformId)) {
    visitorId = storage.get('visitorId');
    if (!visitorId) {
      visitorId = randomString(32);
      storage.set('visitorId', visitorId);
    }
  }

  growthBook.setAttributes({
    loggedIn: browserService.getCookies()?.['picflow-loggedin'] === 'true',
    visitorId,
  });

  return http
    .get(`https://cdn.growthbook.io/api/features/${config.growthBookKey}`)
    .pipe(
      catchError(() => {
        console.warn('Failed to load feature flags');
        return of(null);
      }),
      map(resp => resp?.['features'])
    )
    .pipe(
      tap(features => {
        if (features) growthBook.setFeatures(features);
      })
    );
};
