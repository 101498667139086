import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { from, map, of, switchMap } from 'rxjs';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokProductComparisonFeatureGroupsResolver: ResolveFn<unknown> = route => {
  const storyblokService = inject(StoryblokService);

  return inject(StoryblokService)
    .getUniqueComponentsList(route)
    .pipe(
      switchMap(components => {
        if (components.has('ProductComparisonList') || components.has('ProductComparison')) {
          return from(storyblokService.getStory('technical/product-comparison-config', route)).pipe(
            map(resp => resp && resp.story.content.featureGroups)
          );
        }
        return of(undefined);
      })
    );
};
