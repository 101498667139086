import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { getAllRouteData } from '../lib/get-all-route-data';
import { getFullPath } from '../lib/get-full-path';
import { Rating, RatingsService } from '../services/ratings.service';
import { StoryblokHelperService } from '../services/storyblok-helper.service';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokCustomRatingsResolver: ResolveFn<Observable<Rating[]>> = route => {
  const path = getAllRouteData(route).storyblokPathOverride || getFullPath(route).join('/') || 'home';

  const storyblokService = inject(StoryblokService);
  const storyblokHelperService = inject(StoryblokHelperService);

  const ratingsService = inject(RatingsService);

  return storyblokService.getStory(path, route).pipe(
    switchMap(resp => {
      const story = resp && resp.story;
      if (!story) return of(undefined);
      const customRatingComponents = storyblokHelperService.getBlocks(
        story.content.body,
        ['CustomRatingDisplay'],
        true
      );
      const needsCustomRatings = !!customRatingComponents.filter(r => r.ratingName !== 'picflow').length;
      if (!needsCustomRatings) return of(undefined);
      return ratingsService.getCustomRatings();
    })
  );
};
