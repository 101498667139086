import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { StoryblokService } from '../services/storyblok.service';
// import { Components } from './components';
import { Competitor } from '../services/compare-replace.service';

export const storyblokCompetitorsResolver: ResolveFn<Observable<Competitor[]>> = route => {
  const storyblokService = inject(StoryblokService);

  return inject(StoryblokService)
    .getUniqueComponentsList(route)
    .pipe(
      switchMap(components => {
        if (components.has('CompetitorList')) {
          return storyblokService.getStories({ content_type: 'Competitor' }) as Observable<Competitor[]>;
        }
        return of(undefined);
      })
    );
};
