import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { getFullPath } from '../lib/get-full-path';
import { AnalyticsService } from '../services/analytics.service';
import { BrowserService } from '../services/browser.service';
import { RedirectService } from '../services/redirect.service';
import { ToastService } from '../services/toast.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInRedirectGuard {
  private redirectService = inject(RedirectService);
  private browserService = inject(BrowserService);
  private analytics = inject(AnalyticsService);
  private toast = inject(ToastService);

  async canActivate(next: ActivatedRouteSnapshot): Promise<UrlTree | boolean> {
    const loggedIn = this.browserService.getCookies()?.['picflow-loggedin'] === 'true';
    if (!loggedIn) return true;

    const path = getFullPath(next);

    if (path[0] === 'r') {
      if (!this.toast.toasts$.value.length) {
        this.toast.add('Invites are only valid for new accounts', 'error', { duration: 5000 });
      }
    }

    if (path[0] === 'auth' && ['login', 'signup'].includes(path[1])) {
      return this.redirectService.redirectTo(['a', 'home']);
    }

    if (!this.analytics.lastUrl && path.length === 0) {
      return this.redirectService.redirectTo(['a', 'home']);
    }

    if (['promo', 'pricing'].includes(path[0])) {
      return this.redirectService.redirectTo(['a', 'pricing']);
    }
    return true;
  }
}
