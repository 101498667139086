import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { PricingService } from '../services/pricing.service';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokBackendPricesResolver: ResolveFn<unknown> = route => {
  const pricingService = inject(PricingService);
  return inject(StoryblokService)
    .getUniqueComponentsList(route)
    .pipe(
      switchMap(components => {
        if (
          components.has('PricingBoxList') ||
          components.has('ProductComparison') ||
          components.has('ProductComparisonList')
        ) {
          return pricingService.getPrices('main').pipe(take(1));
        }
        return of(undefined);
      })
    );
};
