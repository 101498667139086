import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of, switchMap, take } from 'rxjs';
import { PricingService } from '../services/pricing.service';
import { StoryblokService } from '../services/storyblok.service';

export const storyblokSeatAddonPricesResolver: ResolveFn<unknown> = route => {
  const pricingService = inject(PricingService);
  const storyblokService = inject(StoryblokService);

  return storyblokService.getUniqueComponentsList(route).pipe(
    switchMap(components => {
      if (components.has('ProductComparisonList')) {
        return pricingService.getPrices('seat_addon').pipe(take(1));
      }
      return of(undefined);
    })
  );
};
